import { BannerMapper } from "@/data/persistences/mappers/BannerMapper";
import { BannerRepositoryInterface } from "@/data/persistences/repositories/contracts/BannerRepositoryInterface";
import { BannerDetailData } from "@/domain/entities/Banner";
import { BannerEndpoint } from "../../endpoints/horde/BannerEndpoint";
import ApiService from "../../services/ApiService";

export class BannerApiRepository implements BannerRepositoryInterface {
  private service: ApiService;
  private mapper: BannerMapper;
  private endpoints: BannerEndpoint;

  constructor(
    service: ApiService,
    mapper: BannerMapper,
    endpoints: BannerEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getBannerList(): Promise<BannerDetailData[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListBanner(),
      {}
    );
    const res = this.mapper.convertListDataFromApi(resp);
    return res;
  }
}
