export class BannerDetailData {
  announcements = "";
  bannerName = "";
  startDate = "";
  endDate = "";
  priority = 0;
  image = "";
  url = "";
  status = "";
  constructor(fields?: Partial<BannerDetailData>) {
    Object.assign(this, fields);
  }
}
