/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ConvertObjectCamelToSnakeCase,
  formatTimeCustom
} from "@/app/infrastructures/misc/Utils";
import { BalanceMinusPenaltyRequestInterface } from "../contracts/BalanceMinusPenaltyRequest";
import { BalanceMinusPenaltyDetail } from "@/domain/entities/BalanceMinusPenalty";
import formatNumber from "@/app/infrastructures/misc/common-library/FormatNumber";
import { OptionsClass } from "@/domain/entities/MainApp";

export class BalanceMinusPenaltyApiRequest
  implements BalanceMinusPenaltyRequestInterface {
  payload: BalanceMinusPenaltyDetail = new BalanceMinusPenaltyDetail();
  constructor(fields?: Partial<BalanceMinusPenaltyApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    const {
      createdAt,
      updatedAt,
      createdBy,
      updatedBy,
      includeDayOffData,
      embargoCodTypeData,
      statusData,
      ...items
    } = this.payload;

    items.cutOffTime.setHours(items.cutOffTime.getHours() + 7, 0, 0, 0);

    const reorderTypeEmbargo = (arr: OptionsClass[]): string => {
      const lastIndex = arr.length - 1;

      // Cari indeks elemen dengan value "origin"
      const originIndex = arr.findIndex(
        (item: OptionsClass) => item.value === "origin"
      );

      // Jika elemen dengan value "origin" ada di akhir array, pindahkan ke posisi pertama
      if (originIndex === lastIndex) {
        const originItem = arr.pop(); // Hapus elemen "origin" dari akhir
        arr.unshift(originItem!); // Tambahkan elemen "origin" ke awal array
      }

      // Gabungkan elemen-elemen array dengan tanda koma sebagai pemisah
      return arr.map((item: OptionsClass) => item.value).join(",");
    };

    return JSON.stringify(
      ConvertObjectCamelToSnakeCase({
        ...items,
        percentagePenalty: formatNumber(items.percentagePenalty, {
          reset: true
        }),
        percentageAfterPenalty: formatNumber(items.percentageAfterPenalty, {
          reset: true
        }),
        cutOffTime: items.cutOffTime.toISOString().replace(/:/gi, "."),
        maxMinusBalance: formatNumber(items.maxMinusBalance, {
          reset: true
        }),
        includeDayOff: this.payload.includeDayOffData.value === "yes",
        embargoCodType: reorderTypeEmbargo(this.payload.embargoCodTypeData)
      })
    );
  }
}
