import { BannerDetailData } from "@/domain/entities/Banner";
import { AxiosResponse } from "axios";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class BannerMapper {
  public convertListDataFromApi(res: AxiosResponse): BannerDetailData[] {
    const { data } = res;
    const bannerData: BannerDetailData[] = [];

    if (!data.data.length) {
      return [];
    }
    data.data.map((item: any) => {
      bannerData.push(
        new BannerDetailData({
          bannerName: item.cb_name,
          startDate: item.cb_start_date,
          endDate: item.cb_end_date,
          priority: item.cb_priority,
          url: item.cb_url_cta,
          status: item.cb_status,
          image: parsingLinkGcpToAws(item.cb_image_url)
        })
      );
    });
    return bannerData;
  }
}
