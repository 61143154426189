
import { Vue, prop, Options } from "vue-class-component";
import CustomToast from "./CustomToast.vue";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  title = prop<string>({
    type: String,
    default: ""
  });
  isClickable = prop<boolean>({
    type: Boolean,
    default: true
  });
}

@Options({
  emits: ["update:modelValue", "onClose"],
  components: {
    CustomToast
  }
})
export default class MultipleSnackbar extends Vue.with(Props) {
  dataSnackbar = [
    {
      status: "success",
      cargoNumber: "FLIGHT-JOG-1669090649025",
      destination: "JOG"
    },
    {
      status: "failed",
      cargoNumber: "FLIGHT-JOG-1669090649025",
      destination: "JOG"
    },
    {
      status: "success",
      cargoNumber: "FLIGHT-JOG-1669090649025",
      destination: "JOG"
    },
    {
      status: "failed",
      cargoNumber: "FLIGHT-JOG-1669090649025",
      destination: "JOG"
    }
  ];
  showMultiple() {
    this.dataSnackbar.forEach(item => {
      this.$toast.add({
        severity: item.status === "success" ? "success" : "error",
        detail: `<b>No.Kargo ${item.cargoNumber}</b> ${
          item.status === "success" ? "Berhasil" : "Gagal"
        } Booking kargo ke <b>${item.destination}</b>`
      });
    });
  }
}
