<template>
  <Teleport to="body">
    <div ref="container" :class="containerClass" v-bind="$attrs">
      <div class="justify-between flex flex-row">
        <p v-if="messages?.length > 0" class="text-14px font-montserrat font-semibold py-2">{{ title }}</p>
        <div v-if="messages?.length > 1" class="flex flex-row">
          <div
            class="rounded-full px-2 py-0 my-2 mr-1 cursor-pointer text-center bg-red-lp-100 text-white text-12px"
            @click="clearAll"
          >
            Tutup semua
          </div>
          <img
            src="@/app/ui/assets/svg/close-icon-notif.svg"
            :class="`w-4 cursor-pointer`"
            alt="chevron-up"
            @click="clearAll"
          />
        </div>
      </div>
      <transition-group name="p-toast-message" tag="div">
        <CustomToastMessage
          v-for="msg of messages"
          :key="msg.id"
          :message="msg"
          @close="remove($event)"
          :isClickable="isClickable"
        />
      </transition-group>
    </div>
  </Teleport>
</template>
<script>
import Toast from "primevue/toast/Toast.vue";
import CustomToastMessage from "./CustomToastMessage.vue";

export default {
  extends: Toast,
  props: {
    title: {
      type: String,
      required: true
    },
    isClickable: {
      type: Boolean,
      required: true
    }
  },
  components: {
    CustomToastMessage
  },
  methods: {
    clearAll() {
      this.$toast.removeAllGroups();
    }
  }
};
</script>
