import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";
import { BannerDetailData } from "@/domain/entities/Banner";

const announcements =
  "<p>1. Dapat mengakses halaman booking dari halaman cek tarif</p>" +
  "<p>2. Dapat melihat informasi embargo (apabila ada) dihalaman cek tarif</p>" +
  "<p>3. Pembaharuan logo Genesis</p>";

// FILL IF SENDER HAVE ANNOUNCEMENT
const BANNER_ANNOUNCEMENT_SENDER: BannerDetailData = new BannerDetailData({});

// FILL IF GENESIS HAVE ANNOUNCEMENT
export const BANNER_ANNOUNCEMENT_GENESIS: BannerDetailData = new BannerDetailData(
  {
    announcements,
    startDate: "10-02-2022", // format month-date-year
    endDate: "10-09-2022" // format month-date-year
  }
);

export const BANNER_ANNOUNCEMENT: BannerDetailData = IS_SENDER_ACCOUNT
  ? BANNER_ANNOUNCEMENT_SENDER
  : BANNER_ANNOUNCEMENT_GENESIS;
