/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { BalanceMinusPenaltyPresenter } from "../presenters/BalanceMinusPenaltyPresenter";
import { BalanceMinusPenaltyApiRequest } from "@/data/payload/api/BalanceMinusPenaltyApiRequest";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "balance-minus-penalty"
})
class BalanceMinusPenaltyStore extends VuexModule {
  @Action
  public getList(params: ApiRequestList) {
    const presenter = container.resolve(BalanceMinusPenaltyPresenter);
    return presenter.getList(params);
  }

  @Action
  public getDetail(id: number) {
    const presenter = container.resolve(BalanceMinusPenaltyPresenter);
    return presenter.getDetail(id);
  }

  @Action
  public edit(payload: BalanceMinusPenaltyApiRequest) {
    const presenter = container.resolve(BalanceMinusPenaltyPresenter);
    return presenter.edit(payload);
  }

  @Action
  public getAllNotifications() {
    const presenter = container.resolve(BalanceMinusPenaltyPresenter);
    return presenter.getAllNotifications();
  }

  @Action
  public getBalancePenaltyNotification() {
    const presenter = container.resolve(BalanceMinusPenaltyPresenter);
    return presenter.getBalancePenaltyNotification();
  }
}

export const BalanceMinusPenaltyController = getModule(
  BalanceMinusPenaltyStore
);
