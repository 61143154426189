<template>
  <div
    :class="containerClass"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div
      class="p-toast-message-content cursor-pointer"
      :class="convertTheme(message.severity)"
    >
      <div @click="toCargoList">
        <span :class="iconClass"></span>
        <div class="p-toast-message-text">
          <div class="p-toast-detail" v-sanitize-html="message.detail"></div>
        </div>
      </div>
      <button
        @click="onCloseClick"
        v-if="message.closable !== false"
        type="button"
        v-ripple
      >
        <span class="p-toast-icon-close-icon pi pi-times pt-6"></span>
      </button>
    </div>
  </div>
</template>

<script>
import ToastMessage from "primevue/toast/ToastMessage.vue";

export default {
  props: {
    isClickable: {
      type: Boolean,
      required: true
    }
  },
  extends: ToastMessage,
  methods: {
    toCargoList() {
      this.isClickable && this.$router.push("/cargo-list");
    },

    convertTheme(color) {
      const themes = {
        "succsess": "my-toast-success",
        "error": "my-toast-error",
        "warning": "my-toast-warning"
      }

      return themes[color];
    }
  }
};
</script>
