
import {
  getAccessBanner,
  getToken,
  setCredentialBanner
} from "@/app/infrastructures/misc/Cookies";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import formatDateWithDay from "@/app/infrastructures/misc/common-library/FormatDateWithDay";
import { Options, Vue } from "vue-class-component";
import { MainAppController } from "../../controllers/MainAppController";
import { BannerController } from "../../controllers/BannerController";
import ImagePreview from "@/app/ui/components/image-preview/index.vue";
import { BANNER_ANNOUNCEMENT } from "./components/misc";
import { BannerDetailData } from "@/domain/entities/Banner";

@Options({
  components: { ImagePreview }
})
export default class Banner extends Vue {
  mounted() {
    this.getBanners();
  }

  isBanner = false;
  banners: BannerDetailData[] = [];
  isLoading = false;

  async getBanners() {
    const token = !!getToken();
    const banner = !getAccessBanner();
    try {
      this.isLoading = true;
      this.banners = await BannerController.getBannerList();
      if (token && banner && this.banners.length) {
        this.isBanner = true;
        return this.banners.filter(item => !item.announcements);
      }
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Menampilkan Banner !", () =>
          this.getBanners()
        )
      );
    } finally {
      this.isLoading = false;
    }
  }

  get announcementData() {
    return this.expiredDate(
      BANNER_ANNOUNCEMENT.startDate,
      BANNER_ANNOUNCEMENT.endDate
    )
      ? BANNER_ANNOUNCEMENT
      : "";
  }

  expiredDate(start: string, end: string): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return (
      (today >= new Date(start) && !end) ||
      (today >= new Date(start) && today <= new Date(end))
    );
  }

  onHidden() {
    setCredentialBanner(String(new Date().getTime()));
    this.isBanner = false;
  }

  formatDate(date: string) {
    return formatDateWithDay(date);
  }
  get maxHeight() {
    return (5 / 6) * MainAppController.screenSize.height;
  }
}
