import { BannerComponent } from "@/app/infrastructures/dependencies/modules/BannerComponent";
import store from "@/store";
import { container } from "tsyringe";
import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import { BannerPresenter } from "../presenters/BannerPresenter";
BannerComponent.init();
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "banner"
})
class BannerStore extends VuexModule {
  @Action
  public getBannerList() {
    const presenter = container.resolve(BannerPresenter);
    return presenter.getBannerList();
  }
}
export const BannerController = getModule(BannerStore);
