import { BannerPresenter } from "@/app/ui/presenters/BannerPresenter";
import { BannerMapper } from "@/data/persistences/mappers/BannerMapper";
import { container } from "tsyringe";
import { BannerEndpoint } from "../../endpoints/horde/BannerEndpoint";
import { BannerApiRepository } from "../../repositories/api/BannerApiRepository";
import ApiService from "../../services/ApiService";

export class BannerComponent {
  public static init() {
    container.register<BannerApiRepository>(BannerApiRepository, {
      useFactory: d => {
        return new BannerApiRepository(
          d.resolve(ApiService),
          d.resolve(BannerMapper),
          d.resolve(BannerEndpoint)
        );
      }
    });
    container.register<BannerMapper>(BannerMapper, {
      useClass: BannerMapper
    });
    container.register<BannerPresenter>(BannerPresenter, {
      useFactory: d => {
        return new BannerPresenter(d.resolve(BannerApiRepository));
      }
    });
  }
}
