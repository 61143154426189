<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M8.232 2.399c.47-.469 1.105-.732 1.768-.732s1.299.263 1.768.732c.402.402.653.928.716 1.488.91.428 1.696 1.085 2.28 1.911.696.985 1.07 2.162 1.07 3.369v2.631c0 .113.022.225.065.33.043.104.106.199.186.279l1.17 1.17c.24.239.31.597.182.909-.13.311-.433.514-.77.514h-3.334c0 .884-.35 1.732-.976 2.357-.625.625-1.473.976-2.357.976-.884 0-1.732-.35-2.357-.976-.625-.625-.976-1.473-.976-2.357H3.333c-.337 0-.64-.203-.77-.514-.129-.312-.057-.67.181-.909l1.17-1.17c.162-.162.253-.38.253-.608V9.167c0-2.334 1.37-4.347 3.349-5.28.063-.56.314-1.086.716-1.488zM8.333 15c0 .442.176.866.489 1.178.312.313.736.489 1.178.489.442 0 .866-.176 1.178-.489.313-.312.489-.736.489-1.178H8.333zM10 3.333c-.221 0-.433.088-.59.244-.156.157-.243.369-.243.59v.284c0 .353-.223.668-.556.785-1.62.573-2.778 2.118-2.778 3.93V11.8c0 .557-.184 1.096-.52 1.534h9.373c-.133-.174-.243-.365-.327-.568-.127-.306-.192-.635-.192-.967V9.167c0-.862-.267-1.703-.765-2.407-.497-.704-1.2-1.236-2.013-1.524-.333-.117-.556-.432-.556-.785v-.284c0-.221-.087-.433-.244-.59-.156-.156-.368-.244-.589-.244z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script>
import { Vue } from "vue-class-component";

export default class Notification extends Vue {}
</script>
