import { BannerApiRepository } from "@/app/infrastructures/repositories/api/BannerApiRepository";
import { BannerDetailData } from "@/domain/entities/Banner";
import { injectable } from "tsyringe";

@injectable()
export class BannerPresenter {
  private repository: BannerApiRepository;

  constructor(repository: BannerApiRepository) {
    this.repository = repository;
  }

  public getBannerList(): Promise<BannerDetailData[]> {
    return this.repository.getBannerList();
  }
}
